import { withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { LocaleContext } from 'App'
import React, { useContext, useState } from 'react'
import {ExternalLinkSvg} from './AllSvg'

const ExternalModal = ({ handleConfirm, handleCancel }) => {
	const { t } = useTranslation('common')
	const lang = useContext(LocaleContext)
  
	return (
		<div className="popup">
            <div className="popup-inner">
				<ExternalLinkSvg />
				<p>
					{t('external-link.text')}
				</p>
				<button
					className="yes"
					onClick={handleConfirm}
				>
					{t('external-link.yes')}
				</button>
				<button
					className="no"
					onClick={handleCancel}
				>
					{t('external-link.no')}
				</button>
            </div>
		</div>
	)
}

export default ExternalModal