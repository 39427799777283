import { lazy } from 'react';

// passenger pages
const PassengerHome = lazy(() => import('../pages/passenger/Home'))
const Line = lazy(() => import('../pages/passenger/Line'))
const Map = lazy(() => import('../pages/passenger/Map'))
const Station = lazy(() => import('../pages/passenger/Station'))
const Tour = lazy(() => import('../pages/passenger/Tour'))
const LostFoundPage = lazy(() => import('../pages/passenger/LostFoundPage'))
const ViewAll = lazy(() => import('components/passenger/home/ViewAll'))
const Search = lazy(() => import('../pages/passenger/Search'))
const TraverseAround = lazy(() => import('../pages/passenger/TraverseAround'))
const TravelAdvisory = lazy(() => import('../pages/passenger/TravelAdvisory'))
const ScreenReaderAccess = lazy(() => import('../pages/passenger/ScreenReaderAccess'))
const Kiosk = lazy(() => import('../components/common/Kiosk'))
const KioskPassenger = lazy(() => import('../components/common/KioskPassenger'))
const KioskMuseum = lazy(() => import('../components/common/KioskMuseum'))
const NetworkMap1 = lazy(() => import('../components/passenger/map/NetworkMap1'))
const Details = lazy(() => import('../pages/passenger/Detail'))
const Sitemap = lazy(() => import('../pages/passenger/Sitemap'))

// corporate pages
const CorporateHome = lazy(() => import('../pages/corporate/Home'))
const CorporateSearch = lazy(() => import('../pages/corporate/CorporateSearch'))
const NewCorporateHome = lazy(() => import('../pages/newCorporate/Home'))
const PropertyResult = lazy(() => import('../pages/newCorporate/PropertyResult'))
const Login = lazy(() => import('../pages/newCorporate/Login'))
const Register = lazy(() => import('../pages/newCorporate/Register'))
const ProfileCreated = lazy(() => import('../pages/newCorporate/ProfileCreated'))
const VacantProperties = lazy(() => import('../pages/newCorporate/VacantProperties'))
const MyAccount = lazy(() => import('../components/newCorporate/MyAccount'))
const EnquiredProperty = lazy(() => import('../components/newCorporate/EnquiredProperty'))

// consultancy services pages
const ConsultancyServicesHome = lazy(() => import('../pages/consultancyServices/Home'))

const publicRoutes = [
    { path: "/", component: PassengerHome },
    { path: "/map", component: Map },
    { path: "/line/:name", component: Line },
    { path: "/station/:id", component: Station },
    { path: "/tour-guide", component: Tour },
    { path: "/view-all", component: ViewAll },
    { path: "/kiosk", component: Kiosk },
    { path: "/passenger-kiosk", component: KioskPassenger },
    { path: "/museum-kiosk", component: KioskMuseum },
    { path: "/network_map", component: NetworkMap1 },
    { path: "/lost-found-list", component: LostFoundPage },
    { path: "/travel-advisory", component: TravelAdvisory },
    { path: "/screen-reader-access", component: ScreenReaderAccess },
    { path: "/sitemap", component: Sitemap },
    { path: "/search/:id", component: Search },
    { path: "/corporate/search/:id", component: CorporateSearch },
    { path: "/traverse-around", component: TraverseAround },
    { path: "/corporate", component: CorporateHome },
    { path: "/new-corporate", component: NewCorporateHome },
    { path: "/new-corporate/property/search", component: PropertyResult },
    { path: "/new-corporate/vacant", component: VacantProperties },
    { path: "/consultancy-services", component: ConsultancyServicesHome }

]

const passengerRoutes = [
    { path: "/:name", component: Details }
]

const authRoutes = [
    { path: "/new-corporate/login", component: Login },
    { path: "/new-corporate/register", component: Register },
    { path: "/new-corporate/profile-created", component: ProfileCreated }
]

const profileRoutes = [
    { path: "/new-corporate/my-account", component: MyAccount },
    { path: "/new-corporate/my-properties", component: EnquiredProperty }
]

export const routes = (isLogin, isPassenger) => {
    if (isLogin) {
        const arr = isPassenger ? publicRoutes.concat(passengerRoutes, profileRoutes) : publicRoutes.concat(profileRoutes)
        return arr
    }

    if (!isLogin) {
        const arr = isPassenger ? publicRoutes.concat(passengerRoutes, authRoutes) : publicRoutes.concat(authRoutes)
        return arr
    }
}