import { LocaleContext } from 'App'
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Logoatech from '../../assets/arohatech-logo.png'
import Niclogo from '../../assets/NIC-LOGO.png'
import XLogo from 'assets/x-logo.png'
import EmblemIcon from 'assets/theme-2024/white-emblem.png'
import ExternalModal from '../common/ExternalModal.js'
import { API_ENDPOINT } from 'helpers/pageUtils'

const Footer = () => {
	const lang = useContext(LocaleContext)
  const { t } = useTranslation('common')
  const { t: tPassenger } = useTranslation('passenger')
	const [showNewWindowPopup, setShowNewWindowPopup] = useState(false);
	const [externalLink, setExternalLink] = useState('');
	const [showFeedback, setShowFeedback] = useState(null);
	const [rating, setRating] = useState(0);
	const [hover, setHover] = useState(0);
	const [dataFeedback, setDataFeedback] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [description, setDescription] = useState('');
	const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    description: '',
    rating: ''
	});
	
	const openInNewWindow = (event, link) => {
		event.preventDefault()
		setExternalLink(link)
		setShowNewWindowPopup(true)
	}
	
	const handleConfirmNewWindow = () => {
		// Open the link in a new window with specific dimensions
		window.open(externalLink, '_blank', 'noopener,noreferrer,width=800,height=600');
		setShowNewWindowPopup(false)
	}
	
	const handleCancelNewWindow = () => {
		setShowNewWindowPopup(false)
	}
	
	const showFeedbackForm = () => {
		setShowFeedback(!showFeedback);
	}
	
	const submitFeedback = async () => {
		let formErrors = {};
		let isValid = true;

		// Validate first name
		if (!firstName.trim()) {
			formErrors.firstName = tPassenger('home.feedback.form.validation.first-name-required');
			isValid = false;
		} else if (!/^[a-zA-Z]{1,}$/.test(firstName)) {
			formErrors.firstName = tPassenger('home.feedback.form.validation.invalid-first-name');
			isValid = false;
		}

		// Validate last name
		if (!lastName.trim()) {
			formErrors.lastName = tPassenger('home.feedback.form.validation.last-name-required');
			isValid = false;
		} else if (!/^[a-zA-Z]{1,}$/.test(lastName)) {
			formErrors.lastName = tPassenger('home.feedback.form.validation.invalid-last-name');
			isValid = false;
		}

		// Validate email
		if (!email.trim()) {
			formErrors.email = tPassenger('home.feedback.form.validation.email-required');
			isValid = false;
		} else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
			formErrors.email = tPassenger('home.feedback.form.validation.invalid-email');
			isValid = false;
		}

		// Validate phone
		if (!phone.trim()) {
			formErrors.phone = tPassenger('home.feedback.form.validation.phone-required');
			isValid = false;
		} else if (!/^\d{10}$/.test(phone)) {
			formErrors.phone = tPassenger('home.feedback.form.validation.invalid-phone');
			isValid = false;
		}

		// Validate description
		if (!description.trim()) {
			formErrors.description = tPassenger('home.feedback.form.validation.description-required');
			isValid = false;
		}

		// Validate rating
		if (rating < 1 || rating > 5) {
			formErrors.rating = tPassenger('home.feedback.form.validation.rating-required');
			isValid = false;
		}

		// Set errors if any
		setErrors(formErrors);

		if (!isValid) return;

		// Prepare data to be submitted
		const feedbackData = {
			name: `${firstName} ${lastName}`, // Combine firstName and lastName into name
			email,
			phone,
			remarks: description, // Rename description to remarks
			rating,
		};

		// Call the API to submit feedback
		try {
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(feedbackData),
			};
			const res = await window.fetch(`${API_ENDPOINT}/post_feedback/`, requestOptions);
			const json = await res.json();
			setDataFeedback(json);
			setShowFeedback(false); // Close form
		} catch (error) {
			console.log({ error });
		}
	};
	
  return (
    <section className='fcc-section footer'>
			{showNewWindowPopup ?
				<ExternalModal
					handleConfirm={handleConfirmNewWindow}
					handleCancel={handleCancelNewWindow}
				/>
			:
				''
			}
      <div className='container'>
        <div className='row'>
          <div className='col-md-6 col-lg-3'>
            <h3>{t('corporate-footer.contact-info')}</h3>
            <p>
              {t('footer.main-footer-address-line1')}<br/>
               {t('footer.main-footer-address-line2')}
              <br />
              {t('footer.main-footer-address-line3')}
            </p>
			</div>
          <div className='col-md-6 col-lg-6'>
            <h3>{t('corporate-footer.quick-links')}</h3>
            <div className='row'>
              <div className='col-md-6 '>
                <ul className='footerLinks'>
                  <li>
                    <Link to={lang === 'en' ? '/pages/en/dust-control-measures-implemented-at-sites' : '/pages/hi/dust-control-measures-implemented-at-sites'}>
                      {t('corporate-footer.quick-sub-links1')}
                    </Link>
                  </li>
                  <li className="d-none">
                    <a href='http://www.delhimetrorail.com/DMRC-Museum/index.html' target="_blank">{t('corporate-footer.quick-sub-links2')}</a>
                  </li>
                  <li>
                    <Link to={lang === 'en' ? '/pages/en/vendor-payment-details' : '/pages/hi/vendor-payment-details'}>{t('corporate-footer.quick-sub-links3')}</Link>
                  </li>
				 <li>
                    <a href='https://services.billdesk.com/MercOnline/web/delhi-metro/pay' target="_blank">{t('corporate-footer.cus-pay')}</a>
                  </li>
				 <li>
                    <Link to='/pages/en/list-of-md-awardees'>{t('corporate-footer.list-awardees')}</Link>
                  </li>
                  <li className="d-none">
                    <Link to='/pages/en/corporate/last-mile-services-limited'>{t('corporate-footer.quick-sub-links4')}</Link>
                  </li>
                  <li className="d-none">
                    <Link to='/pages/en/corporate/puppet-shows'>{t('corporate-footer.quick-sub-links5')}</Link>
                  </li>
                  
                </ul>
              </div>
              <div className='col-md-6'>
                <ul className='footerLinks'>
                  <li>
                    <Link to={lang === 'en' ? '/pages/en/public-notice' : '/pages/hi/public-notice'}>{t('corporate-footer.quick-sub-links8')}</Link>
                  </li>
                 <li className="d-none">
                    <Link to='/pages/en/corporate/facilities-for-superannuated-employees'>{t('corporate-footer.quick-sub-links10')}</Link>
                  </li>
				  <li>
                    <Link to={lang === 'en' ? '/pages/en/stores-procurement-information' : '/pages/hi/stores-procurement-information'}>{t('corporate-footer.quick-sub-links13')}</Link>
                  </li>
                  <li>
                    <Link to={lang === 'en' ? '/pages/en/contact-us' : '/pages/hi/contact-us'}>{t('corporate-footer.quick-sub-links11')}</Link>
                  </li>
                 <li className="d-none">
                    <Link to={`/${lang}/corporate`}>{t('corporate-footer.quick-sub-links6')}</Link>
                  </li>
                  <li>
                    <a href='https://pgportal.gov.in/' target="_blank">{t('corporate-footer.quick-sub-links7')}</a>
                  </li>
                  <li>
                    <a href='/screen-reader-access'>{t('corporate-footer.help')}</a>
                  </li>
                  <li>
                    <a href="#" onClick={showFeedbackForm}>{t('footer.feedback')}</a>
                  </li>
                  <li>
                    <a href="https://www.india.gov.in/" onClick={(e) => openInNewWindow(e, 'https://www.india.gov.in/')} alt={t('footer.national-portal')} title={t('footer.national-portal')} target="_blank">
											{t('footer.national-portal')}
										</a>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-lg-2 d-none'>
            <h3>{t('footer.quick-contact')}</h3>
            <div className=''>
              <h6>{t('footer.quick-contact1')}</h6>
              <h3 className='mb-2'>{t('footer.quick-contact1-num')}</h3>
              <h6>{t('footer.quick-contact2')}</h6>
              <h3>{t('footer.quick-contact2-num')}</h3>
              <h6>{t('corporate-footer.follow-us')} :</h6>
              <ul className='sociallinks d-flex'>
                <li>
                  <a href='https://www.facebook.com/officialdmrc/' target="_blank">
                    <i className='fa fa-facebook' />
                  </a>
                </li>
                <li>
                  <a href='https://twitter.com/OfficialDMRC' target="_blank">
                    <img src={XLogo} alt="Twitter" width="32" height="32"/>
                  </a>
                </li>
                <li>
                 <a href='https://www.instagram.com/officialdmrc/?hl=en' target="_blank">
                    <i className='fa fa-instagram' />
                  </a>
                </li>
              </ul>
            </div>
          </div>
		   <div className='col-md-6 col-lg-3 footerpA'>
		  <a href={lang === 'en' ? '/pages/en/web-information-manager' : '/pages/hi/web-information-manager'}><p>{t('corporate-footer.web-info')}</p></a>
				<a href="https://www.arohatech.com/" target="_blank"><p><img src={Logoatech} alt="Arohatech - Web Portal Development Company and Mobile App Developer for Delhi Metro Rail Corporation (DMRC) India" title="Arohatech - Web Portal Development Company and Mobile App Developer for Delhi Metro Rail Corporation (DMRC) India" width="100"  style={{marginBottom:5}}/> <br/ >Designed, Developed & Maintained by Arohatech IT Services</p></a>
				<a href="https://www.nic.in/" target="_blank"><p><img src={Niclogo} alt="National Informatics Center" width="100" height="23" style={{marginBottom:5}}/> <br/ > Hosted by National Informatics Center</p></a>
        </div>
        </div>
		<div className="row">
			<div className="col-md-12 corp-copyright mb-4" style={{ backgroundColor: "none" }}>
			  <a href="https://www.india.gov.in/" target="_blank" title="Indian Government" onClick={(e) => openInNewWindow(e, 'https://www.india.gov.in/')}>
					<img src={EmblemIcon} alt='Emblem' width="283" height="82" />
				</a>
			</div>
		</div>
		<div className="row">
			<div className="col-md-12 corp-copyright" style={{ backgroundColor: "none" }}>
			  © {new Date().getFullYear()} {t('corporate-footer.all-rights')}
			</div>
		</div>
	</div>
	{showFeedback &&
		<div id='feedback-form' style={{display: 'block'}} className='modal feedback-form'>
			<div className='modal-dialog modal-dialog-centered'>
				<div className='modal-content'>
					<div className='circle-close-btn'>
						<button
							id="buttonDismiss1"
							className='btn-close '
							style={{top: 15, right: 12}}
							data-bs-dismiss='modal'
							aria-label='Close'
							onClick={showFeedbackForm}
						/>
					</div>
					<div className='modal-body'>
						<h1>{tPassenger('home.feedback.form.heading')}</h1>
						<h2>{tPassenger('home.feedback.form.sub-heading')}</h2>
						<form className="row mt-4">
							<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<label htmlFor="firstName">{tPassenger('home.feedback.form.fields.first-name')}</label>
								<input
									className='form-control'
									type='text'
									id="firstName"
									placeholder={tPassenger('home.feedback.form.fields.first-name')}
									aria-label={tPassenger('home.feedback.form.fields.first-name')}
									value={firstName}
									onChange={(e) => setFirstName(e.target.value)}
									aria-invalid={errors.firstName ? 'true' : 'false'}
									aria-describedby="firstNameError" // Linking error message
								/>
								{errors.firstName && <small id="firstNameError" className="text-danger">{errors.firstName}</small>}
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<label htmlFor="lastName">{tPassenger('home.feedback.form.fields.last-name')}</label>
								<input
									className='form-control'
									type='text'
									id="lastName"
									placeholder={tPassenger('home.feedback.form.fields.last-name')}
									aria-label={tPassenger('home.feedback.form.fields.last-name')}
									value={lastName}
									onChange={(e) => setLastName(e.target.value)}
									aria-invalid={errors.lastName ? 'true' : 'false'}
									aria-describedby="lastNameError" // Linking error message
								/>
								{errors.lastName && <small id="lastNameError" className="text-danger">{errors.lastName}</small>}
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-2">
								<label htmlFor="email">{tPassenger('home.feedback.form.fields.email')}</label>
								<input
									className='form-control'
									type='text'
									id="email"
									placeholder={tPassenger('home.feedback.form.fields.email')}
									aria-label={tPassenger('home.feedback.form.fields.email')}
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									aria-invalid={errors.email ? 'true' : 'false'}
									aria-describedby="emailError" // Linking error message
								/>
								{errors.email && <small id="emailError" className="text-danger">{errors.email}</small>}
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-2">
								<label htmlFor="phone">{tPassenger('home.feedback.form.fields.phone')}</label>
								<input
									className='form-control'
									type='text'
									id="phone"
									placeholder={tPassenger('home.feedback.form.fields.phone')}
									aria-label={tPassenger('home.feedback.form.fields.phone')}
									value={phone}
									onChange={(e) => setPhone(e.target.value)}
									aria-invalid={errors.phone ? 'true' : 'false'}
									aria-describedby="phoneError" // Linking error message
								/>
								{errors.phone && <small id="phoneError" className="text-danger">{errors.phone}</small>}
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<p className="mt-4">{tPassenger('home.feedback.tell-us')}</p>
								<div>
									{[...Array(5)].map((star, index) => {
										const ratingValue = index + 1;
										const isFilled = ratingValue <= (hover || rating);

										return (
											<span
												key={index}
												id="rating"
												style={{ cursor: 'pointer', color: isFilled ? '#FFD700' : '#DDDDDD' }}
												onClick={() => setRating(ratingValue)}
												onMouseEnter={() => setHover(ratingValue)}
												onMouseLeave={() => setHover(0)}
												aria-invalid={errors.rating ? 'true' : 'false'}
												aria-describedby="ratingError" // Linking error message
											>
												★
											</span>
										);
									})}
								</div>
								{errors.rating && <small id="ratingError" className="text-danger">{errors.rating}</small>}
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
								<label htmlFor="description">{tPassenger('home.feedback.form.fields.description')}</label>
								<textarea
									rows='3'
									className='form-control'
									id="description"
									placeholder={tPassenger('home.feedback.form.fields.description')}
									aria-label={tPassenger('home.feedback.form.fields.description')}
									value={description}
									onChange={(e) => setDescription(e.target.value)}
									aria-invalid={errors.description ? 'true' : 'false'}
									aria-describedby="descriptionError" // Linking error message
								/>
								{errors.description && <small id="descriptionError" className="text-danger">{errors.description}</small>}
							</div>
							<div className="col-lg-24 col-md-24 col-sm-24 col-xs-24 mt-4 d-flex justify-content-end">
								<button
									className='form-control feedback-btn'
									aria-label={tPassenger('home.feedback.form.fields.submit')}
									onClick={(e) => {
										e.preventDefault();  // Prevent form submission
										submitFeedback();
									}}
								>
									{tPassenger('home.feedback.form.fields.submit')}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	}
    </section>
  )
}

export default Footer
